<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      :lock-scroll="false"
      :close-on-click-modal="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">添加屏蔽公司</div>
        <div class="updatabox">
          <div class="bbbbb">
            <div class="inputbox">
              <!-- prefix-icon="el-icon-search" -->
              <el-input
                v-model="inputtext"
                placeholder="搜索公司名称"
              ></el-input>
              <div class="btn font16" @click="getCompanyList">搜索</div>
            </div>
            <div class="tishi" v-if="!inputtext">
              <div class="t1 font16">可通过以下方式搜索公司</div>
              <div class="t2 font14">
                • 公司全称：如
                <span>“杭州优灿科技有限公司”</span>
              </div>
            </div>
            <div class="" v-if="tableData != null">
              <el-table
                ref="multipleTable"
                :show-header="false"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="35"></el-table-column>
                <el-table-column>
                  <template slot-scope="scope">
                    <div class="caozuo font16" style="color: #111111">
                      {{ scope.row.companyName }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="sub">
                <el-button type="primary" @click="closebox()"
                  >屏蔽所选公司</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      inputtext: "",
      tableData: null,
      multipleSelection: [],
      callback: null,
    };
  },
  mounted() {
    Bus.$on("addgsstuts", (data) => {
      this.dialogVisible = data.show;
      this.tableData = null;
      this.callback = data.callback;
    });
  },
  watch: {
    inputtext(v) {
      // console.log(v);
      if (v == "") {
        this.tableData = null;
      }
    },
  },
  methods: {
    getCompanyList() {
      this.tableData = [];

      this.$api
        .searchCompany("get", { keyword: this.inputtext })
        .then((res) => {
          this.tableData = res.data;
        });
    },
    closebox() {
      this.inputtext = "";
      this.tableData = [];

      let idArr = [];
      this.multipleSelection.map((item) => {
        idArr.push(item.id);
      });
      this.$api
        .blackCompany("post", { ids: idArr.join(","), status: 1 })
        .then(() => {
          this.dialogVisible = false;
          this.callback();
        });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  padding-bottom: 20px;
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}
.updatabox {
  display: flex;
  align-items: center;
  justify-content: center;
  // flex-direction: column;

  padding: 78px 0 80px 0;
  .bbbbb {
    .inputbox {
      width: 500px;
      display: flex;
      align-items: center;

      margin-bottom: 20px;
      /deep/ .el-input__inner {
        border-radius: 0px;
        width: 100%;
        height: 46px;
        border: 1px solid #00bcff;
        text-align: center;
      }
      .btn {
        width: 88px;
        height: 46px;
        background-color: #00bcff;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .tishi {
    .t1 {
      color: #666666;
    }
    .t2 {
      color: #999999;
      margin-top: 10px;
      span {
        color: #111111;
      }
    }
  }
}

.sub {
  /deep/ .el-button {
    border-radius: 0px;
    width: 100%;
    margin-top: 100px;
    background: #00bcff;
    border: none;
  }
}
</style>
