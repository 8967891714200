<template>
  <div class="">
    <el-card class="box-card">
      <div class="buyprop">
        <el-button type="primary" icon="el-icon-circle-plus" @click="addpb()"
          >添加屏蔽公司</el-button
        >
      </div>
      <!-- 列表空 -->
      <div class="nodata" v-if="false">
        <img src="../../assets/images/common/search-no.png" />
        <div class="font18">您还没有屏蔽过任何公司哦~</div>
      </div>

      <div class="">
        <div class="shuoming font18">屏蔽公司说明：</div>
        <div class="shuoming-text font14">
          将公司设置为屏蔽公司后，该公司将无法查看到你的公开简历，你最多可以添加10个屏蔽公司。
        </div>
        <div class="" style="margin-top: 30px">
          <el-table
            :data="tableData"
            v-loading="table_loading"
            :show-header="false"
            element-table_loading-text="加载中"
            class="boderline"
          >
            <el-table-column prop="companyName"></el-table-column>
            <el-table-column width="200">
              <template slot-scope="scope">
                <div class="caozuo">
                  <el-button type="text" @click="del(scope.row.id)"
                    >移除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!-- add -->
      <addpbcompany></addpbcompany>
    </el-card>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
import addpbcompany from "./addpbcompany.vue";
export default {
  components: {
    addpbcompany,
  },
  data() {
    return {
      tableData: [],
      table_loading: false,
    };
  },
  created() {
    this.getBlackCompanyList();
  },
  mounted() {},
  methods: {
    getBlackCompanyList() {
      this.$api.getBlackCompanyList("get").then((res) => {
        this.tableData = res.data;
      });
    },
    del(id) {
      this.$api.blackCompany("post", { ids: id, status: 0 }).then(() => {
        this.getBlackCompanyList();
        this.$message({
          message: "移除成功",
          type: "success",
        });
      });
    },
    addpb() {
      Bus.$emit("addgsstuts", {
        show: true,
        callback: () => {
          this.getBlackCompanyList();
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-card {
  border-radius: 0px;
  border: none;
  box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}
/deep/.el-card__body {
  padding: 0 30px 150px 30px;
}
.buyprop {
  margin: 30px 0;
  /deep/ .el-button {
    border-radius: 0px;
    width: 135px;
    background: #00bcff;
    border: none;
  }
}
.boderline {
  width: 100%;
  border-right: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
}
/deep/.el-table td,
/deep/.el-table th {
  color: #111111;
  font-size: 14px;
}
.caozuo {
  text-align: center;
  .el-button {
    color: #00bcff;
  }
}
.shuoming {
  color: #111111;
  margin-top: 30px;
}
.shuoming-text {
  color: #999999;
  margin-top: 20px;
}
</style>
